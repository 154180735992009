import React from 'react';
import BitCoinCalculator from './pages/BitCoinCalculator';
import './app.css';

export default function App() {

  return (
    <div style={{overflowX : 'hidden'}}>
      <BitCoinCalculator/>
    </div>
  );
}