import React from 'react';
import { Grid, Button, OutlinedInput, Divider, TextField, Select, MenuItem, Switch, Checkbox, FormControlLabel, InputLabel } from '@material-ui/core';
import { isMobile, browserName } from 'react-device-detect';
import GeneralChart from './GeneralChart';
import Chart from './Chart';
import BitCoinCalculatorHeader from './Header';
import BuySaleButtons from './BuySaleButtons';
import { connect } from 'react-redux';
import SyncIcon from '@material-ui/icons/Sync';
import actions from 'redux/action';
import moment from 'moment';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

const { fetchRateData, fetchChartData, fetchCustomChartData, fetchChartByRange, fetchChartByDateAndRange, fetchCurrencyExchangeRate } = actions;


class Calculator extends React.PureComponent {
    constructor(props) {
        super(props);
        this.renderChart = this.renderChart.bind(this);
    }
    state = {
        chartType: 'general-chart',
        bitCoinToCurrencies: true,
        bitcoins: 1,
        amount: 0,
        activeTab: 0,
        currency: window.localStorage.length > 0 ? window.localStorage.getItem('currency') : 'USD',
        rateData: {},
        timespan: '7days',
        startingDate: moment(Date.now()).subtract(7, 'd').format("YYYY-MM-DD"),
        endingDate: moment(Date.now()).format("YYYY-MM-DD"),
        chartHeight: 400,
        prefersTheme: localStorage.getItem('prefersTheme') ? localStorage.getItem('prefersTheme') : "light",
        getChartByRange: true,
        getChartByDate: false,
        startingRange: 0,
        endingRange: 10000
    };

    componentDidMount() {
        this.props.fetchCurrencyExchangeRate({ currency: this.state.currency });
        this.props.fetchRateData();
        this.exchangeBitcoinToAmount({ target: { value: this.state.bitcoins } })
        document.addEventListener('fullscreenchange', (event) => {
            if (document.fullscreenElement) {
                this.setState({ chartHeight: 821 })
            } else {
                this.setState({ chartHeight: 400 })
            }
        });
    }

    onTimeSpanChange(e) {
        this.setState({ timespan: e.target.value })
        switch (e.target.value) {
            case '7days':
                this.setState({ startingDate: moment(Date.now()).subtract(7, 'd').format("YYYY-MM-DD") })
                break;
            case '15days':
                this.setState({ startingDate: moment(Date.now()).subtract(15, 'd').format("YYYY-MM-DD") })
                break;
            case '30days':
                this.setState({ startingDate: moment(Date.now()).subtract(30, 'd').format("YYYY-MM-DD") })
                break;
            case '60days':
                this.setState({ startingDate: moment(Date.now()).subtract(60, 'd').format("YYYY-MM-DD") })
                break;
            case '180days':
                this.setState({ startingDate: moment(Date.now()).subtract(180, 'd').format("YYYY-MM-DD") })
                break;
            case '1year':
                this.setState({ startingDate: moment(Date.now()).subtract(1, 'y').format("YYYY-MM-DD") })
                break;
            case '3years':
                this.setState({ startingDate: moment(Date.now()).subtract(3, 'y').format("YYYY-MM-DD") })
                break;
            case 'all':
                this.setState({ startingDate: moment("01-01-2009").format("YYYY-MM-DD") })
            default:
                break;
        }
        if (this.state.chartType !== "general-chart") {
            this.props.fetchChartData({ type: this.state.chartType, timespan: e.target.value })
        }
    }

    onTypeChange(e) {
        this.setState({ chartType: e })
        console.log(e);
        if (e !== 'general-chart') {
            this.props.fetchChartData({ type: e, timespan: this.state.timespan })
        }
    }
    fullScreen() {
        var isInFullScreen = (document.fullscreenElement && document.fullscreenElement !== null) ||
            (document.webkitFullscreenElement && document.webkitFullscreenElement !== null) ||
            (document.mozFullScreenElement && document.mozFullScreenElement !== null) ||
            (document.msFullscreenElement && document.msFullscreenElement !== null);

        var docElm = document.getElementById('chart');
        if (!isInFullScreen) {
            if (docElm.requestFullscreen) {
                docElm.requestFullscreen().then(() => { this.setState({ chartHeight: 821 }) });
            } else if (docElm.mozRequestFullScreen) {
                docElm.mozRequestFullScreen().then(() => { this.setState({ chartHeight: 821 }) });
            } else if (docElm.webkitRequestFullScreen) {
                docElm.webkitRequestFullScreen().then(() => { this.setState({ chartHeight: 821 }) });
            } else if (docElm.msRequestFullscreen) {
                docElm.msRequestFullscreen().then(() => { this.setState({ chartHeight: 821 }) });
            }
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen().then(() => this.setState({ chartHeight: 400 }));
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen().then(() => this.setState({ chartHeight: 400 }));
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen().then(() => this.setState({ chartHeight: 400 }));
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen().then(() => this.setState({ chartHeight: 400 }));
            }
        }
    }

    currencyChanged1(e) {
        window.localStorage.setItem('currency', e.target.value);
        this.setState({ currency: e.target.value });
        const tempdata1 = this.state.bitcoins;
        const e2 = {
            target: {
                value: tempdata1
            }
        }
        const tempdata2 = this.state.amount;
        const e3 = {
            target: {
                value: tempdata2
            }
        }
        this.exchangeBitcoinToAmount(e2)
        // this.exchangeAmountToBitcoin(e3)
        this.props.fetchChartData({ type: this.state.chartType, timespan: this.state.timespan });
    }

    currencyChanged2(e) {
        window.localStorage.setItem('currency', e.target.value);
        this.setState({ currency: e.target.value });
        const tempdata1 = this.state.bitcoins;
        const e2 = {
            target: {
                value: tempdata1
            }
        }
        const tempdata2 = this.state.amount;
        const e3 = {
            target: {
                value: tempdata2
            }
        }
        // this.exchangeBitcoinToAmount(e2)
        this.exchangeAmountToBitcoin(e3)
        this.props.fetchChartData({ type: this.state.chartType, timespan: this.state.timespan });
    }

    exchangeBitcoinToAmount(e) {
        this.setState({ bitcoins: e.target.value })
        let tempdata;
        let amount;
        if (this.state.currency === 'USD') {
            tempdata = this.props.rateData.data.USD
            amount = tempdata.last * (e.target.value)
            this.setState({ amount: amount.toFixed(2) })
        }
        if (this.state.currency === 'AUD') {
            tempdata = this.props.rateData.data.AUD
            amount = tempdata.last * (e.target.value)
            this.setState({ amount: amount.toFixed(2) })
        }
        if (this.state.currency === 'BRL') {
            tempdata = this.props.rateData.data.BRL
            amount = tempdata.last * (e.target.value)
            this.setState({ amount: amount.toFixed(2) })
        }
        if (this.state.currency === 'CAD') {
            tempdata = this.props.rateData.data.CAD
            amount = tempdata.last * (e.target.value)
            this.setState({ amount: amount.toFixed(2) })
        }
        if (this.state.currency === 'CHF') {
            tempdata = this.props.rateData.data.CHF
            amount = tempdata.last * (e.target.value)
            this.setState({ amount: amount.toFixed(2) })
        }
        if (this.state.currency === 'CLP') {
            tempdata = this.props.rateData.data.CLP
            amount = tempdata.last * (e.target.value)
            this.setState({ amount: amount.toFixed(2) })
        }
        if (this.state.currency === 'CNY') {
            tempdata = this.props.rateData.data.CNY
            amount = tempdata.last * (e.target.value)
            this.setState({ amount: amount.toFixed(2) })
        }
        if (this.state.currency === 'DKK') {
            tempdata = this.props.rateData.data.DKK
            amount = tempdata.last * (e.target.value)
            this.setState({ amount: amount.toFixed(2) })
        }
        if (this.state.currency === 'EUR') {
            tempdata = this.props.rateData.data.EUR
            amount = tempdata.last * (e.target.value)
            this.setState({ amount: amount.toFixed(2) })
        }
        if (this.state.currency === 'GBP') {
            tempdata = this.props.rateData.data.GBP
            amount = tempdata.last * (e.target.value)
            this.setState({ amount: amount.toFixed(2) })
        }
        if (this.state.currency === 'HKD') {
            tempdata = this.props.rateData.data.HKD
            amount = tempdata.last * (e.target.value)
            this.setState({ amount: amount.toFixed(2) })
        }
        if (this.state.currency === 'INR') {
            tempdata = this.props.rateData.data.INR
            amount = tempdata.last * (e.target.value)
            this.setState({ amount: amount.toFixed(2) })
        }
        if (this.state.currency === 'ISK') {
            tempdata = this.props.rateData.data.ISK
            amount = tempdata.last * (e.target.value)
            this.setState({ amount: amount.toFixed(2) })
        }
        if (this.state.currency === 'JPY') {
            tempdata = this.props.rateData.data.JPY
            amount = tempdata.last * (e.target.value)
            this.setState({ amount: amount.toFixed(2) })
        }
        if (this.state.currency === 'KRW') {
            tempdata = this.props.rateData.data.KRW
            amount = tempdata.last * (e.target.value)
            this.setState({ amount: amount.toFixed(2) })
        }
        if (this.state.currency === 'NZD') {
            tempdata = this.props.rateData.data.NZD
            amount = tempdata.last * (e.target.value)
            this.setState({ amount: amount.toFixed(2) })
        }
        if (this.state.currency === 'PLN') {
            tempdata = this.props.rateData.data.PLN
            amount = tempdata.last * (e.target.value)
            this.setState({ amount: amount.toFixed(2) })
        }
        if (this.state.currency === 'RUB') {
            tempdata = this.props.rateData.data.RUB
            amount = tempdata.last * (e.target.value)
            this.setState({ amount: amount.toFixed(2) })
        }
        if (this.state.currency === 'SEK') {
            tempdata = this.props.rateData.data.SEK
            amount = tempdata.last * (e.target.value)
            this.setState({ amount: amount.toFixed(2) })
        }
        if (this.state.currency === 'THB') {
            tempdata = this.props.rateData.data.THB
            amount = tempdata.last * (e.target.value)
            this.setState({ amount: amount.toFixed(2) })
        }
        if (this.state.currency === 'TRY') {
            tempdata = this.props.rateData.data.TRY
            amount = tempdata.last * (e.target.value)
            this.setState({ amount: amount.toFixed(2) })
        }
        if (this.state.currency === 'TWD') {
            tempdata = this.props.rateData.data.TWD
            amount = tempdata.last * (e.target.value)
            this.setState({ amount: amount.toFixed(2) })
        }
    }

    exchangeAmountToBitcoin(e) {
        this.setState({ amount: e.target.value })
        let tempdata;
        let bitcoins;
        if (this.state.currency === 'USD') {
            tempdata = this.props.rateData.data.USD
            bitcoins = 1 / tempdata.last * (e.target.value)
            this.setState({ bitcoins: bitcoins.toFixed(8) })
        }
        if (this.state.currency === 'AUD') {
            tempdata = this.props.rateData.data.AUD
            bitcoins = 1 / tempdata.last * (e.target.value)
            this.setState({ bitcoins: bitcoins.toFixed(8) })
        }
        if (this.state.currency === 'BRL') {
            tempdata = this.props.rateData.data.BRL
            bitcoins = 1 / tempdata.last * (e.target.value)
            this.setState({ bitcoins: bitcoins.toFixed(8) })
        }
        if (this.state.currency === 'CAD') {
            tempdata = this.props.rateData.data.CAD
            bitcoins = 1 / tempdata.last * (e.target.value)
            this.setState({ bitcoins: bitcoins.toFixed(8) })
        }
        if (this.state.currency === 'CHF') {
            tempdata = this.props.rateData.data.CHF
            bitcoins = 1 / tempdata.last * (e.target.value)
            this.setState({ bitcoins: bitcoins.toFixed(8) })
        }
        if (this.state.currency === 'CLP') {
            tempdata = this.props.rateData.data.CLP
            bitcoins = 1 / tempdata.last * (e.target.value)
            this.setState({ bitcoins: bitcoins.toFixed(8) })
        }
        if (this.state.currency === 'CNY') {
            tempdata = this.props.rateData.data.CNY
            bitcoins = 1 / tempdata.last * (e.target.value)
            this.setState({ bitcoins: bitcoins.toFixed(8) })
        }
        if (this.state.currency === 'DKK') {
            tempdata = this.props.rateData.data.DKK
            bitcoins = 1 / tempdata.last * (e.target.value)
            this.setState({ bitcoins: bitcoins.toFixed(8) })
        }
        if (this.state.currency === 'EUR') {
            tempdata = this.props.rateData.data.EUR
            bitcoins = 1 / tempdata.last * (e.target.value)
            this.setState({ bitcoins: bitcoins.toFixed(8) })
        }
        if (this.state.currency === 'GBP') {
            tempdata = this.props.rateData.data.GBP
            bitcoins = 1 / tempdata.last * (e.target.value)
            this.setState({ bitcoins: bitcoins.toFixed(8) })
        }
        if (this.state.currency === 'HKD') {
            tempdata = this.props.rateData.data.HKD
            bitcoins = 1 / tempdata.last * (e.target.value)
            this.setState({ bitcoins: bitcoins.toFixed(8) })
        }
        if (this.state.currency === 'INR') {
            tempdata = this.props.rateData.data.INR
            bitcoins = 1 / tempdata.last * (e.target.value)
            this.setState({ bitcoins: bitcoins.toFixed(8) })
        }
        if (this.state.currency === 'ISK') {
            tempdata = this.props.rateData.data.ISK
            bitcoins = 1 / tempdata.last * (e.target.value)
            this.setState({ bitcoins: bitcoins.toFixed(8) })
        }
        if (this.state.currency === 'JPY') {
            tempdata = this.props.rateData.data.JPY
            bitcoins = 1 / tempdata.last * (e.target.value)
            this.setState({ bitcoins: bitcoins.toFixed(8) })
        }
        if (this.state.currency === 'KRW') {
            tempdata = this.props.rateData.data.KRW
            bitcoins = 1 / tempdata.last * (e.target.value)
            this.setState({ bitcoins: bitcoins.toFixed(8) })
        }
        if (this.state.currency === 'NZD') {
            tempdata = this.props.rateData.data.NZD
            bitcoins = 1 / tempdata.last * (e.target.value)
            this.setState({ bitcoins: bitcoins.toFixed(8) })
        }
        if (this.state.currency === 'PLN') {
            tempdata = this.props.rateData.data.PLN
            bitcoins = 1 / tempdata.last * (e.target.value)
            this.setState({ bitcoins: bitcoins.toFixed(8) })
        }
        if (this.state.currency === 'RUB') {
            tempdata = this.props.rateData.data.RUB
            bitcoins = 1 / tempdata.last * (e.target.value)
            this.setState({ bitcoins: bitcoins.toFixed(8) })
        }
        if (this.state.currency === 'SEK') {
            tempdata = this.props.rateData.data.SEK
            bitcoins = 1 / tempdata.last * (e.target.value)
            this.setState({ bitcoins: bitcoins.toFixed(8) })
        }
        if (this.state.currency === 'THB') {
            tempdata = this.props.rateData.data.THB
            bitcoins = 1 / tempdata.last * (e.target.value)
            this.setState({ bitcoins: bitcoins.toFixed(8) })
        }
        if (this.state.currency === 'TRY') {
            tempdata = this.props.rateData.data.TRY
            bitcoins = 1 / tempdata.last * (e.target.value)
            this.setState({ bitcoins: bitcoins.toFixed(8) })
        }
        if (this.state.currency === 'TWD') {
            tempdata = this.props.rateData.data.TWD
            bitcoins = 1 / tempdata.last * (e.target.value)
            this.setState({ bitcoins: bitcoins.toFixed(8) })
        }
    }

    renderChart() {
        if (this.state.chartType === 'general-chart') {
            return <GeneralChart
                chartProps={{
                    chartHeight: this.state.chartHeight,
                    type: this.state.chartType, timespan:
                        this.state.timespan, custom: this.state.custom,
                    startingDate: this.state.startingDate, endingDate: this.state.endingDate,
                    theme: this.state.prefersTheme, currency: this.state.currency
                }} />
        }
        return (
            <div>
                <Chart
                    chartData={this.props.chartData}
                    currencyExchangeRate={this.props.currencyExchangeRate}
                    chartProps={{
                        chartHeight: this.state.chartHeight,
                        type: this.state.chartType,
                        timespan: this.state.timespan,
                        custom: this.state.custom,
                        startingDate: this.state.startingDate, endingDate: this.state.endingDate,
                        theme: this.state.prefersTheme,
                        currency: this.state.currency,
                    }} />
            </div>)
    }

    getCustomChartData() {
        var startingTimeStamp = moment(this.state.startingDate).unix();
        var endingTimeStamp = moment(this.state.endingDate).unix();
        if (endingTimeStamp - startingTimeStamp >= 604800) {
            this.props.fetchCustomChartData({ type: this.state.chartType, timespan: 'all', startingTimeStamp: startingTimeStamp, endingTimeStamp: endingTimeStamp });
        }
        else if (startingTimeStamp - endingTimeStamp > 0) {
            return alert("Select Valid dates")
        }
    }
    getRangedChartData() {
        var startingRange = this.state.startingRange === '' ? 0 : parseInt(this.state.startingRange)
        var endingRange = this.state.endingRange === '' ? alert("Ending Range not valid") : parseInt(this.state.endingRange)
        if (endingRange - startingRange >= 0) {
            this.props.fetchChartByRange({ type: this.state.chartType, timespan: 'all', startingRange: startingRange, endingRange: endingRange });
        }
        else if (startingRange - endingRange > 0) {
            return alert("Select Valid dates")
        }
    }

    getRangedAndDateChart() {
        var startingTimeStamp = moment(this.state.startingDate).unix();
        var endingTimeStamp = moment(this.state.endingDate).unix();
        var startingRange = this.state.startingRange === '' ? 0 : parseInt(this.state.startingRange);
        var endingRange = this.state.endingRange === '' ? alert("Ending Range not valid") : parseInt(this.state.endingRange);
        if (endingTimeStamp - startingTimeStamp >= 604800) {
            if (endingRange - startingRange >= 0) {
                this.props.fetchChartByDateAndRange({ type: this.state.chartType, timespan: 'all', startingRange: startingRange, endingRange: endingRange, startingTimeStamp: startingTimeStamp, endingTimeStamp: endingTimeStamp });
            }
            else if (startingRange - endingRange > 0) {
                return alert("Select Valid dates")
            }
        }
        else if (startingTimeStamp - endingTimeStamp > 0) {
            return alert("Select Valid dates")
        }

    }
    changeRenderPosition() {
        switch (this.state.bitCoinToCurrencies) {
            case true:
                this.setState({ bitCoinToCurrencies: false });
                break;
            case false:
                this.setState({ bitCoinToCurrencies: true });
                break;
            default:
                break;
        }
    }


    render() {
        return (
            <ThemeProvider theme={
                createMuiTheme({
                    palette: {
                        type: this.state.prefersTheme
                    }
                })} >
                <div>
                    <CssBaseline />
                    <BitCoinCalculatorHeader theme={this.state.prefersTheme} />
                    <div style={{ position: 'fixed', right: '10px', top: '10px', zIndex: '9999' }}>
                        <Switch onChange={() => { this.state.prefersTheme === 'dark' ? this.setState({ prefersTheme: "light" }) : this.setState({ prefersTheme: "dark" }); this.state.prefersTheme === 'dark' ? localStorage.setItem('prefersTheme', 'light') : localStorage.setItem('prefersTheme', 'dark') }} />
                    </div>
                    <Grid container spacing={3}>
                        <Grid item xs={5}>
                            <div>
                                {this.state.bitCoinToCurrencies === true ? <label>Bitcoin</label> : <label>Currency</label>}
                                {this.state.bitCoinToCurrencies === true ?
                                    <OutlinedInput value={this.state.bitcoins} onChange={e => { this.exchangeBitcoinToAmount(e) }} type="number" style={{ width: '100%', height: '45px', marginBottom: '20px' }} /> :
                                    <OutlinedInput value={this.state.amount} onChange={e => { this.exchangeAmountToBitcoin(e) }} type="number" style={{ width: '100%', height: '45', marginBottom: '20px' }} />
                                }
                                {this.state.bitCoinToCurrencies === true ?
                                    <Select
                                        variant="outlined"
                                        value="BTC"
                                        style={{ width: '100%', height: '45px', marginBottom: '20px' }}
                                    >
                                        <MenuItem value="BTC">BTC</MenuItem>
                                    </Select> :
                                    <Select
                                        variant="outlined"
                                        onChange={e => { this.currencyChanged1(e) }}
                                        value={this.state.currency}
                                        style={{ width: '100%', height: '45px', marginBottom: '20px' }}
                                    >
                                        <MenuItem value="USD">USD</MenuItem>
                                        <MenuItem value="AUD">AUD</MenuItem>
                                        <MenuItem value="BRL">BRL</MenuItem>
                                        <MenuItem value="CAD">CAD</MenuItem>
                                        <MenuItem value="CHF">CHF</MenuItem>
                                        <MenuItem value="CNY">CNY</MenuItem>
                                        <MenuItem value="DKK">DKK</MenuItem>
                                        <MenuItem value="EUR">EUR</MenuItem>
                                        <MenuItem value="GBP">GBP</MenuItem>
                                        <MenuItem value="HKD">HKD</MenuItem>
                                        <MenuItem value="INR">INR</MenuItem>
                                        <MenuItem value="ISK">ISK</MenuItem>
                                        <MenuItem value="JPY">JPY</MenuItem>
                                        <MenuItem value="KRW">KRW</MenuItem>
                                        <MenuItem value="NZD">NZD</MenuItem>
                                        <MenuItem value="PLN">PLN</MenuItem>
                                        <MenuItem value="RUB">RUB</MenuItem>
                                        <MenuItem value="SEK">SEK</MenuItem>
                                        <MenuItem value="THB">THB</MenuItem>
                                        <MenuItem value="TRY">TRY</MenuItem>
                                    </Select>
                                }
                            </div>
                        </Grid>
                        <Grid item xs={2} style={{ textAlign: 'center', paddingTop: '25px' }}>
                            <SyncIcon onClick={() => { this.changeRenderPosition() }} color="primary" fontSize="large" />
                        </Grid>
                        <Grid item xs={5}>
                            <div>
                                {this.state.bitCoinToCurrencies ? <label>Currency</label> : <label>Bitcoin</label>}
                                {this.state.bitCoinToCurrencies === false ?
                                    <OutlinedInput value={this.state.bitcoins} onChange={e => { this.exchangeBitcoinToAmount(e) }} type="number" style={{ width: '100%', height: '45px', marginBottom: '20px' }} /> :
                                    <OutlinedInput value={this.state.amount} onChange={e => { this.exchangeAmountToBitcoin(e) }} type="number" style={{ width: '100%', height: '45px', marginBottom: '20px' }} />
                                }
                                {this.state.bitCoinToCurrencies === false ?
                                    <Select
                                        variant="outlined"
                                        value="BTC"
                                        style={{ width: '100%', height: '45px', marginBottom: '20px' }}
                                    >
                                        <MenuItem value="BTC">BTC</MenuItem>
                                    </Select> :
                                    <Select
                                        variant="outlined"
                                        onChange={e => { this.currencyChanged2(e) }}
                                        value={this.state.currency}
                                        style={{ width: '100%', height: '45px', marginBottom: '20px', background: 'transparent' }}
                                    >
                                        <MenuItem value="USD">USD</MenuItem>
                                        <MenuItem value="AUD">AUD</MenuItem>
                                        <MenuItem value="BRL">BRL</MenuItem>
                                        <MenuItem value="CAD">CAD</MenuItem>
                                        <MenuItem value="CHF">CHF</MenuItem>
                                        <MenuItem value="CNY">CNY</MenuItem>
                                        <MenuItem value="DKK">DKK</MenuItem>
                                        <MenuItem value="EUR">EUR</MenuItem>
                                        <MenuItem value="GBP">GBP</MenuItem>
                                        <MenuItem value="HKD">HKD</MenuItem>
                                        <MenuItem value="INR">INR</MenuItem>
                                        <MenuItem value="ISK">ISK</MenuItem>
                                        <MenuItem value="JPY">JPY</MenuItem>
                                        <MenuItem value="KRW">KRW</MenuItem>
                                        <MenuItem value="NZD">NZD</MenuItem>
                                        <MenuItem value="PLN">PLN</MenuItem>
                                        <MenuItem value="RUB">RUB</MenuItem>
                                        <MenuItem value="SEK">SEK</MenuItem>
                                        <MenuItem value="THB">THB</MenuItem>
                                        <MenuItem value="TRY">TRY</MenuItem>
                                    </Select>
                                }
                            </div>
                        </Grid>
                    </Grid>
                    <div style={{ textAlign: 'center' }}>
                        <br />
                        <Divider style={{ margin: '20px 0' }} />
                        <br />
                        <h2>
                            {this.state.bitCoinToCurrencies ? <span>{this.state.bitcoins} BTC = {this.state.amount} {this.state.currency}</span> : <span>{this.state.amount} {this.state.currency} = {this.state.bitcoins} BTC</span>}
                        </h2>
                        {/* <br />
                        <TextField variant="outlined" style={{ width: '60%', height: '45px' }} />
                        <br /> */}
                        <BuySaleButtons />
                        <br />
                        <br />
                        <div>
                            <Button onClick={e => { this.onTypeChange('general-chart'); this.setState({ activeTab: 0 }) }} style={{ padding: '10px', backgroundColor: this.state.activeTab === 0 ? 'rgba(3, 3, 6, 0.25)' : 'transparent' }}>General Chart</Button>
                            <Button onClick={e => { this.onTypeChange('market-price'); this.setState({ activeTab: 1 }) }} style={{ padding: '10px', backgroundColor: this.state.activeTab === 1 ? 'rgba(3, 3, 6, 0.25)' : 'transparent' }}>MARKET PRICE</Button>
                            <Button onClick={e => { this.onTypeChange('total-bitcoins'); this.setState({ activeTab: 2 }) }} style={{ padding: '10px', backgroundColor: this.state.activeTab === 2 ? 'rgba(3, 3, 6, 0.25)' : 'transparent' }}>TOTAL CIRCULATING BITCOIN</Button>
                            <Button onClick={e => { this.onTypeChange('market-cap'); this.setState({ activeTab: 3 }) }} style={{ padding: '10px', backgroundColor: this.state.activeTab === 3 ? 'rgba(3, 3, 6, 0.25)' : 'transparent' }}>MARKET CAPITALIZATION</Button>
                            <Button onClick={e => { this.onTypeChange('trade-volume'); this.setState({ activeTab: 4 }) }} style={{ padding: '10px', backgroundColor: this.state.activeTab === 4 ? 'rgba(3, 3, 6, 0.25)' : 'transparent' }}>DAILY TRADE VOLUME</Button>
                            <br />
                            <div id="chart" style={{ marginTop: '20px', backgroundColor: this.state.prefersTheme === 'dark' ? '#303030' : '#ffffff' }}>
                                {isMobile ? null :
                                    browserName !== 'Safari' ?
                                        <Button variant="outlined" onClick={e => this.fullScreen()}>Toggle FullScreen</Button> : null}
                                {this.renderChart()}
                            </div>
                        </div>
                    </div>
                    {this.state.chartType !== "general-chart" ?
                        <div>
                            <div style={{ textAlign: 'center', marginTop: '20px' }}>
                                <FormControlLabel
                                    control={<Checkbox checked={this.state.getChartByRange} onChange={(e) => this.setState({ getChartByRange: e.target.checked })} color="primary" />}
                                    label="Get Chart By Range"
                                    labelPlacement="end"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={this.state.getChartByDate} onChange={(e) => this.setState({ getChartByDate: e.target.checked })} color="primary" />}
                                    label="Get Chart By Date"
                                    labelPlacement="end"
                                />
                            </div>
                            {this.state.getChartByRange && !this.state.getChartByDate ?
                                <div style={{ textAlign: 'center' }}>
                                    <Grid container spacing={5} style={{ marginTop: '20px' }}>
                                        <Grid item xs={6}>
                                            Starting Range:
                                            <br />
                                            <TextField
                                                style={{ width: '150px' }}
                                                id="number"
                                                type="number"
                                                value={this.state.startingRange}
                                                onChange={e => this.setState({ startingRange: e.target.value })}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            Ending Range:
                                            <br />
                                            <TextField
                                                style={{ width: '150px' }}
                                                id="number"
                                                type="number"
                                                value={this.state.endingRange}
                                                onChange={e => this.setState({ endingRange: e.target.value })}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button variant="outlined" onClick={e => { this.getRangedChartData() }} style={{ backgroundColor: this.state.custom ? 'green' : 'transparent' }} >Get Chart</Button>
                                        </Grid>
                                    </Grid>
                                </div> : null
                            }
                            {this.state.getChartByDate && !this.state.getChartByRange ?
                                <div style={{ textAlign: 'center' }}>
                                    <Grid container spacing={5} style={{ marginTop: '20px' }}>
                                        <Grid item xs={6}>
                                            Starting Date:
                                            <br />
                                            <TextField
                                                style={{ width: '150px' }}
                                                id="date"
                                                type="date"
                                                value={this.state.startingDate}
                                                onChange={e => this.setState({ startingDate: e.target.value })}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            Ending Date:
                                            <br />
                                            <TextField
                                                style={{ width: '150px' }}
                                                id="date"
                                                type="date"
                                                value={this.state.endingDate}
                                                onChange={e => this.setState({ endingDate: e.target.value })}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button variant="outlined" onClick={e => { this.getCustomChartData() }} style={{ backgroundColor: this.state.custom ? 'green' : 'transparent' }} >Get Chart</Button>
                                        </Grid>
                                    </Grid>
                                </div> : null}
                            {this.state.getChartByRange && this.state.getChartByDate ?
                                <div style={{ textAlign: 'center' }}>
                                    <Grid container spacing={5} style={{ marginTop: '20px' }}>
                                        <Grid item xs={6}>
                                            Starting Date:
                                            <br />
                                            <TextField
                                                style={{ width: '150px' }}
                                                id="date"
                                                type="date"
                                                value={this.state.startingDate}
                                                onChange={e => this.setState({ startingDate: e.target.value })}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            Ending Date:
                                            <br />
                                            <TextField
                                                style={{ width: '150px' }}
                                                id="date"
                                                type="date"
                                                value={this.state.endingDate}
                                                onChange={e => this.setState({ endingDate: e.target.value })}
                                            />
                                        </Grid>
                                        <Grid container spacing={5} style={{ marginTop: '20px' }}>
                                            <Grid item xs={6}>
                                                Starting Range:
                                                <br />
                                                <TextField
                                                    style={{ width: '150px' }}
                                                    id="number"
                                                    type="number"
                                                    value={this.state.startingRange}
                                                    onChange={e => this.setState({ startingRange: e.target.value })}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                Ending Range:
                                                <br />
                                                <TextField
                                                    style={{ width: '150px' }}
                                                    id="number"
                                                    type="number"
                                                    value={this.state.endingRange}
                                                    onChange={e => this.setState({ endingRange: e.target.value })}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button variant="outlined" onClick={e => { this.getRangedAndDateChart() }} style={{ backgroundColor: this.state.custom ? 'green' : 'transparent' }} >Get Chart</Button>
                                        </Grid>
                                    </Grid>
                                </div> : null}
                        </div> : null
                    }
                    <div style={{ textAlign: 'center', marginTop: '20px' }}>
                        <InputLabel>Select Currency for Chart</InputLabel>
                        <Select
                            variant="outlined"
                            onChange={e => { this.currencyChanged1(e) }}
                            value={this.state.currency}
                            style={{ width: '60%', height: '45px', marginBottom: '20px' }}
                        >
                            <MenuItem value="USD">USD</MenuItem>
                            <MenuItem value="AUD">AUD</MenuItem>
                            <MenuItem value="BRL">BRL</MenuItem>
                            <MenuItem value="CAD">CAD</MenuItem>
                            <MenuItem value="CHF">CHF</MenuItem>
                            <MenuItem value="CNY">CNY</MenuItem>
                            <MenuItem value="DKK">DKK</MenuItem>
                            <MenuItem value="EUR">EUR</MenuItem>
                            <MenuItem value="GBP">GBP</MenuItem>
                            <MenuItem value="HKD">HKD</MenuItem>
                            <MenuItem value="INR">INR</MenuItem>
                            <MenuItem value="ISK">ISK</MenuItem>
                            <MenuItem value="JPY">JPY</MenuItem>
                            <MenuItem value="KRW">KRW</MenuItem>
                            <MenuItem value="NZD">NZD</MenuItem>
                            <MenuItem value="PLN">PLN</MenuItem>
                            <MenuItem value="RUB">RUB</MenuItem>
                            <MenuItem value="SEK">SEK</MenuItem>
                            <MenuItem value="THB">THB</MenuItem>
                            <MenuItem value="TRY">TRY</MenuItem>
                        </Select>
                    </div>
                    <div style={{ textAlign: 'center' }}>
                        <Select
                            variant="outlined"
                            onChange={e => { this.onTimeSpanChange(e) }}
                            defaultValue="7days"
                            style={{ width: '200px', margin: '20px', height: '45px', }}
                        >
                            <MenuItem value="7days">7 days</MenuItem>
                            <MenuItem value="15days">15 days</MenuItem>
                            <MenuItem value="30days">30 days</MenuItem>
                            <MenuItem value="60days">60 days</MenuItem>
                            <MenuItem value="180days">180 days</MenuItem>
                            <MenuItem value="1year">1 Year</MenuItem>
                            <MenuItem value="3years">3 Years</MenuItem>
                            <MenuItem value="all">All Times</MenuItem>
                        </Select>
                    </div>
                </div>
            </ThemeProvider>
        )
    }
}

const mapStateToProps = (state) => {
    console.log(state);
    return {
        rateData: state.DataReducer.rateData,
        chartData: state.DataReducer.chartData,
        currencyExchangeRate: state.DataReducer.currencyExchangeRate
    }
}

export default connect(
    mapStateToProps, {
    fetchRateData,
    fetchChartData,
    fetchCustomChartData,
    fetchChartByRange,
    fetchChartByDateAndRange,
    fetchCurrencyExchangeRate
})(Calculator);