import {createStore, combineReducers, applyMiddleware, compose} from 'redux';
import {routerReducer} from 'react-router-redux';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import reducers from 'redux/reducers';
import rootSaga from 'redux/sagas';

const sagaMiddleware = createSagaMiddleware();
const middlewares = [thunk, sagaMiddleware];

const store = createStore(
    combineReducers({
        ...reducers,
        router: routerReducer
    }),
    compose(applyMiddleware(...middlewares))
);
sagaMiddleware.run(rootSaga);
export {store};