import React from 'react';
import { Button, Select, MenuItem } from '@material-ui/core';


class BuySaleButtons extends React.PureComponent {
    state = { link: 'https://partners.etoro.com/B11521_A92941_TClick.aspx' }
    render() {
        return (
            <div>
                <a style={{ textDecoration: 'none' }} rel="noopener noreferrer" href={this.state.link} target='_blank'><Button style={{ backgroundColor: 'blue', color: 'white', margin: '20px' }}>Buy</Button></a>
                <a style={{ textDecoration: 'none' }} rel="noopener noreferrer" href={this.state.link} target='_blank'><Button style={{ backgroundColor: 'red', color: 'white', margin: '20px' }}>Sell</Button></a>
                <br />
                <Select
                    variant="outlined"
                    onChange={e => { this.setState({ link: e.target.value }) }}
                    defaultValue="https://partners.etoro.com/B11521_A92941_TClick.aspx"
                    style={{ width: '200px', marginBottom: '20px', height: '40px' }}
                >
                    <MenuItem value="https://partners.etoro.com/B11521_A92941_TClick.aspx">Etoro</MenuItem>
                    <MenuItem value="https://cex.io/r/0/up118966904/0">CEX.IO</MenuItem>
                    <MenuItem value="https://r.kraken.com/BL351">Kraken</MenuItem>
                    <MenuItem value="https://paybis.idevaffiliate.com/11901.html">Paybis</MenuItem>
                    <MenuItem value="https://xcoins.io/?r=xwsv7b">XCOINS</MenuItem>
                    <MenuItem value="https://go.coinmama.com/visit/?bta=54183&nci=5343">Coinmama</MenuItem>
                    <MenuItem value="https://bittrex.com/Account/Register?referralCode=1SJ-30M-DVF">Bittrex</MenuItem>
                    <MenuItem value="https://bitit.io/?referral=KAVBAWCE">Bitit</MenuItem>
                    <MenuItem value="https://www.binance.com/?ref=35919397">Binance</MenuItem>
                    <MenuItem value="https://paxful.com/?r=712Q9bza5d9">Paxful</MenuItem>
                    <MenuItem value="https://gemini.com/share/6k9694">Gemini</MenuItem>
                    <MenuItem value="https://localbitcoins.com/?ch=agnj">Localbitcoins</MenuItem>
                    <MenuItem value="https://changelly.com/?ref_id=scqifmvsl1br8sz6">Changelly</MenuItem>
                </Select>
            </div>
        )
    }
}

export default BuySaleButtons;