import React, { PureComponent } from 'react';
import CanvasJSReact from './canvasjs.react';
import { isMobile } from 'react-device-detect';
var CanvasJSChart = CanvasJSReact.CanvasJSChart;


class Chart extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      theme: `${localStorage.getItem('theme')}1`,
      startingDate: null,
      endingDate: null,
      chartProps: {},
      datasets: [],
      chartHeight: 400
    }
  }

  componentDidUpdate(nextProps) {
    console.log(this.props);
    if (this.props.chartData) {
      if (nextProps.chartData !== this.props.chartData || nextProps.chartProps.currency !== this.props.chartProps.currency) {
        let tempData = []
        const rate = this.props.currencyExchangeRate[this.props.chartProps.currency];
        this.props.chartData.values.map(v => {
          let year = new Date(v.x * 1000).getFullYear()
          let month = new Date(v.x * 1000).getMonth()
          let date = new Date(v.x * 1000).getDate()
          let tempY = this.props.chartProps.type !== "total-bitcoins" ? (v.y * rate) : v.y;
          tempData.push({
            x: new Date(year, month, date),
            y: isMobile ?
              this.reducingLength(tempY, this.props.chartProps.type)
              : tempY
          })
        }
        )
        this.setState({ datasets: tempData, chartHeight: nextProps.chartProps.chartHeight })
      }
    }
  }
  reducingLength(y, type) {
    switch (type) {
      case 'market-price':
        return y / 1000; //k
      case 'total-bitcoins':
        return y / 1000000; //M
      case 'market-cap':
        return y / 1000000000; //G
      case 'trade-volume':
        return y / 1000000; //M
      default:
        break;
    }
  }
  applyValidSuffix() {
    switch (this.props.chartProps.type) {
      case 'market-price':
        return '#0.##k';
      case 'total-bitcoins':
        return '#0.##M';
      case 'market-cap':
        return '#0.##G';
      case 'trade-volume':
        return '#0.##M';
      default:
        break;
    }
  }
  render() {
    if (this.props.chartData) {
      return (
        <div style={{ textAlign: 'center', margin: isMobile ? '0' : '0 0%' }}>
          <br />
          <CanvasJSChart
            options={{
              backgroundColor: null,
              theme: `${this.props.chartProps.theme}1`,
              zoomEnabled: true,
              height: this.props.chartProps.chartHeight,
              title: {
                text: this.props.chartData.name === "USD Exchange Trade Volume" ? 
                  "Exchange Trade Volume" : this.props.chartData.name === "Market Price (USD)" ? "Market Price" : this.props.chartData.name
              },
              axisX: {
                valueFormatString: 'DD MMMM YYYY'
              },
              axisY: {
                title: this.props.chartData.name === "Bitcoins in circulation" ? "₿" : this.props.chartProps.currency,
                valueFormatString: isMobile ? this.applyValidSuffix() : "####0.##",
                prefix: this.props.chartData.name === "Bitcoins in circulation" ? "₿" : this.props.chartProps.currency,
              },
              toolTip: {
                contentFormatter: (e) => {
                  if (isMobile) {
                    switch (this.props.chartProps.type) {
                      case 'market-price':
                        return `${e.entries[0].dataPoint.x.toString().slice(4, 15)} : ${e.entries[0].dataPoint.y}k`;
                      case 'total-bitcoins':
                        return `${e.entries[0].dataPoint.x.toString().slice(4, 15)} : ${e.entries[0].dataPoint.y}M`;
                      case 'market-cap':
                        return `${e.entries[0].dataPoint.x.toString().slice(4, 15)} : ${e.entries[0].dataPoint.y}G`;
                      case 'trade-volume':
                        return `${e.entries[0].dataPoint.x.toString().slice(4, 15)} : ${e.entries[0].dataPoint.y}M`;
                      default:
                        break;
                    }
                  }
                  return `${e.entries[0].dataPoint.x.toString().slice(4, 15)} : ${e.entries[0].dataPoint.y}`;
                }
              },
              data: [
                {
                  type: 'line',
                  color: 'green',
                  dataPoints: this.state.datasets
                }
              ]
            }
            }
            onRef={ref => this.chart = ref}
          />
        </div>
      )
    } else {
      return <div>Loading</div>
    }
  }
}

export default Chart;