import React from 'react'


class Footer extends React.PureComponent {
    render() {
        return (
            <div>
                <div style={{ textAlign: 'center' }}>Bitcoin API data is sourced from <a href="https://www.blockchain.com/">Blockchain.com</a></div>
                <br />
                <div style={{ textAlign: 'center' }}>All rights reserved. Lithium Gold Ventures, LLC</div>
            </div>
        )
    }
}

export default Footer;