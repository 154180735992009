import React from 'react';
import LightLogo from './lightLogo.png';
import DarkLogo from './darkLogo.png';
import { Container } from '@material-ui/core';
import ShareButton from './ShareButtons';

class BitCoinCalculatorHeader extends React.PureComponent {
    componentDidMount() {
        console.log(this.props.theme);
    }
    render() {
        const Logo = this.props.theme === 'dark' ? DarkLogo : LightLogo;
        return (
            <div style={{ textAlign: 'center'}}>
                <Container maxWidth="sm">
                    <img src={Logo} style={{ width: '250px', height: '100px', objectFit: 'cover' }} alt="logo"/>
                </Container>
                <div style={{ textAlign: 'center' }}>
                    <ShareButton />
                </div>
                <div
                    style={{
                        marginBottom: '20px',
                        padding: '20px'
                    }}>
                    Directions: Use this bitcoin calculator to find out exactly how much your bitcoin is worth in any of the global currencies using real-time exchange rates.
                </div>
            </div>

        )
    }
}

export default BitCoinCalculatorHeader;