import actions from 'redux/action';
import { getGeneralCharValues } from '../utils/helpers';
const initialState = {
    chartData: null,
    rateData: null,
    customResponse: null,
    generalChartData: null,
    currencyExchangeRate: null,
}

const {
    FETCH_CHART_DATA_SUCCESS,
    FETCH_RATE_DATA_SUCCESS,
    FETCH_GENERAL_CHART_DATA_SUCCESS,
    FETCH_CURRENCY_EXCHANGE_RATE_SUCCESS
} = actions;

export default function chartDataReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_CHART_DATA_SUCCESS:
            return Object.assign({}, state, { ...state, chartData: action.payload });
        case FETCH_RATE_DATA_SUCCESS:
            return Object.assign({}, state, { ...state, rateData: action.payload });
        case FETCH_GENERAL_CHART_DATA_SUCCESS:
            console.log(action.currency);
            const rate = state.currencyExchangeRate[action.currency];
            return Object.assign({}, state, {
                generalChartData: getGeneralCharValues(action.payload, rate)
            })
        case FETCH_CURRENCY_EXCHANGE_RATE_SUCCESS:
            return Object.assign({}, state, {
                currencyExchangeRate: action.payload.data.conversion_rates
            })
        default:
            return state
    }
}