import React from 'react';
import {
    EmailIcon,
    FacebookIcon,
    HatenaIcon,
    InstapaperIcon,
    LineIcon,
    LinkedinIcon,
    LivejournalIcon,
    MailruIcon,
    OKIcon,
    PinterestIcon,
    PocketIcon,
    RedditIcon,
    TelegramIcon,
    TumblrIcon,
    TwitterIcon,
    ViberIcon,
    VKIcon,
    WhatsappIcon,
    WorkplaceIcon
} from "react-share";
import {
    EmailShareButton,
    FacebookShareButton,
    HatenaShareButton,
    InstapaperShareButton,
    LineShareButton,
    LinkedinShareButton,
    LivejournalShareButton,
    MailruShareButton,
    OKShareButton,
    PinterestShareButton,
    PocketShareButton,
    RedditShareButton,
    TelegramShareButton,
    TumblrShareButton,
    TwitterShareButton,
    ViberShareButton,
    VKShareButton,
    WhatsappShareButton,
    WorkplaceShareButton
} from "react-share";
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import {CopyToClipboard} from 'react-copy-to-clipboard';

export default function ShareButtons() {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                Share On
            </Button>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem>
                    <div>
                        <CopyToClipboard text={"http://bitcoincalculator.org"}>
                            <button style={{border: 'none', backgroundColor: 'transparent'}} ><FileCopyIcon style={{size: '32px'}} /></button> 
                        </CopyToClipboard>
                    </div>
                </MenuItem>
                <MenuItem>
                    <EmailShareButton style={{ margin: '5px' }} url="http://bitcoincalculator.org">
                        <EmailIcon size={32} round={true} /> 
                    </EmailShareButton>
                </MenuItem>
                <MenuItem>
                    <FacebookShareButton style={{ margin: '5px' }} url="http://bitcoincalculator.org">
                        <FacebookIcon size={32} round={true} />
                    </FacebookShareButton>
                </MenuItem>
                <MenuItem>
                    <HatenaShareButton style={{ margin: '5px' }} url="http://bitcoincalculator.org">
                        <HatenaIcon size={32} round={true} />
                    </HatenaShareButton>
                </MenuItem>
                <MenuItem>
                    <InstapaperShareButton style={{ margin: '5px' }} url="http://bitcoincalculator.org">
                        <InstapaperIcon size={32} round={true} />
                    </InstapaperShareButton>
                </MenuItem>
                <MenuItem>
                    <LineShareButton style={{ margin: '5px' }} url="http://bitcoincalculator.org">
                        <LineIcon size={32} round={true} />
                    </LineShareButton>
                </MenuItem>
                <MenuItem>
                    <LinkedinShareButton style={{ margin: '5px' }} url="http://bitcoincalculator.org">
                        <LinkedinIcon size={32} round={true} />
                    </LinkedinShareButton>
                </MenuItem>
                <MenuItem>
                    <LivejournalShareButton style={{ margin: '5px' }} url="http://bitcoincalculator.org">
                        <LivejournalIcon size={32} round={true} />
                    </LivejournalShareButton>
                </MenuItem>
                <MenuItem>
                    <MailruShareButton style={{ margin: '5px' }} url="http://bitcoincalculator.org">
                        <MailruIcon size={32} round={true} />
                    </MailruShareButton>
                </MenuItem>
                <MenuItem>
                    <OKShareButton style={{ margin: '5px' }} url="http://bitcoincalculator.org">
                        <OKIcon size={32} round={true} />
                    </OKShareButton>
                </MenuItem>
                <MenuItem>
                    <PinterestShareButton style={{ margin: '5px' }} url="http://bitcoincalculator.org">
                        <PinterestIcon size={32} round={true} />
                    </PinterestShareButton>
                </MenuItem>
                <MenuItem>
                    <PocketShareButton style={{ margin: '5px' }} url="http://bitcoincalculator.org">
                        <PocketIcon size={32} round={true} />
                    </PocketShareButton>
                </MenuItem>
                <MenuItem>
                    <RedditShareButton style={{ margin: '5px' }} url="http://bitcoincalculator.org">
                        <RedditIcon size={32} round={true} />
                    </RedditShareButton>
                </MenuItem>
                <MenuItem>
                    <TelegramShareButton style={{ margin: '5px' }} url="http://bitcoincalculator.org">
                        <TelegramIcon size={32} round={true} />
                    </TelegramShareButton>
                </MenuItem>
                <MenuItem>
                    <TumblrShareButton style={{ margin: '5px' }} url="http://bitcoincalculator.org">
                        <TumblrIcon size={32} round={true} />
                    </TumblrShareButton>
                </MenuItem>
                <MenuItem>
                    <TwitterShareButton style={{ margin: '5px' }} url="http://bitcoincalculator.org">
                        <TwitterIcon size={32} round={true} />
                    </TwitterShareButton>
                </MenuItem>
                <MenuItem>
                    <ViberShareButton style={{ margin: '5px' }} url="http://bitcoincalculator.org">
                        <ViberIcon size={32} round={true} />
                    </ViberShareButton>
                </MenuItem>
                <MenuItem>
                    <VKShareButton style={{ margin: '5px' }} url="http://bitcoincalculator.org">
                        <VKIcon size={32} round={true} />
                    </VKShareButton>
                </MenuItem>
                <MenuItem>
                    <WhatsappShareButton style={{ margin: '5px' }} url="http://bitcoincalculator.org">
                        <WhatsappIcon size={32} round={true} />
                    </WhatsappShareButton>
                </MenuItem>
                <MenuItem>
                    <WorkplaceShareButton style={{ margin: '5px' }} url="http://bitcoincalculator.org">
                        <WorkplaceIcon size={32} round={true} />
                    </WorkplaceShareButton>
                </MenuItem>
            </Menu>
        </div>
    );
}
