export const getGeneralCharValues = (arr,rate) => {
    console.log({ arr, rate });
    let tempDatas = [];
    arr.map(i => {
        let tempData = []
        i.values.map(j => {
            const year = new Date(j.x * 1000).getFullYear()
            const month = new Date(j.x * 1000).getMonth()
            const date = new Date(j.x * 1000).getDate()
            tempData.push({
                x: new Date(year, month, date),
                y: i === 1 ? j.y : j.y * rate
            });
        });
        tempDatas.push(tempData);
        console.log(tempDatas);
    });
    return tempDatas;
}