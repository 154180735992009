import React from 'react';
import {connect} from 'react-redux';
import Calculator from './Calculator';
import Footer from './Footer';
import actions from 'redux/action';

const { fetchRateData } = actions;

class BitCoinCalculator extends React.PureComponent {
    componentDidMount() {
        this.props.fetchRateData();
    }
    render() {
        return (
            <div style= {{margin: '0px 20px'}}>
                {
                    this.props.rateData  ?
                    <Calculator />
                    : <div>Loading...</div>
                }
                <Footer />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        rateData: state.DataReducer.rateData,
        chartData: state.DataReducer.chartData,
    }
}

export default connect(mapStateToProps, { fetchRateData })(BitCoinCalculator);