const actions = {
    FETCH_CHART_DATA : 'FETCH_CHART_DATA',
    FETCH_CHART_DATA_SUCCESS : 'FETCH_CHART_DATA_SUCCESS',
    FETCH_RATE_DATA: 'FETCH_RATE_DATA',
    FETCH_RATE_DATA_SUCCESS: 'FETCH_RATE_DATA_SUCCESS',
    FETCH_CUSTOM_CHART_DATA: 'FETCH_CUSTOM_CHART_DATA',
    FETCH_CHART_BY_RANGE : 'FETCH_CHART_BY_RANGE',
    FETCH_CHART_BY_DATE_AND_RANGE: 'FETCH_CHART_BY_DATE_AND_RANGE',
    FETCH_GENERAL_CHART_DATA: 'FETCH_GENERAL_CHART_DATA',
    FETCH_GENERAL_CHART_DATA_SUCCESS: 'FETCH_GENERAL_CHART_DATA_SUCCESS',
    FETCH_CURRENCY_EXCHANGE_RATE: 'FETCH_CURRENCY_EXCHANGE_RATE',
    FETCH_CURRENCY_EXCHANGE_RATE_SUCCESS: 'FETCH_CURRENCY_EXCHANGE_RATE_SUCCESS',
    fetchChartData : (request) => ({
        type: actions.FETCH_CHART_DATA,
        request
    }),
    fetchRateData : () => ({
        type: actions.FETCH_RATE_DATA
    }),
    fetchCustomChartData : (request) => ({
        type: actions.FETCH_CUSTOM_CHART_DATA,
        request
    }),
    fetchChartByRange: (request) => ({
        type: actions.FETCH_CHART_BY_RANGE,
        request
    }),
    fetchChartByDateAndRange: (request) => ({
        type: actions.FETCH_CHART_BY_DATE_AND_RANGE,
        request
    }),
    fetchGeneralChart: (request) => ({
        type: actions.FETCH_GENERAL_CHART_DATA,
        request
    }),
    fetchCurrencyExchangeRate: () =>({
        type: actions.FETCH_CURRENCY_EXCHANGE_RATE
    })
}

export default actions;