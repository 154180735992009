import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import actions from 'redux/action';
import axios from 'axios';

const {
    FETCH_CHART_DATA, FETCH_CHART_DATA_SUCCESS,
    FETCH_RATE_DATA, FETCH_RATE_DATA_SUCCESS,
    FETCH_CUSTOM_CHART_DATA,
    FETCH_CHART_BY_RANGE,
    FETCH_CHART_BY_DATE_AND_RANGE,
    FETCH_GENERAL_CHART_DATA, FETCH_GENERAL_CHART_DATA_SUCCESS,
    FETCH_CURRENCY_EXCHANGE_RATE, FETCH_CURRENCY_EXCHANGE_RATE_SUCCESS,
} = actions;

export function* getChartData() {
    yield takeEvery(FETCH_CHART_DATA, function* (action) {
        try {
            const res = yield call(getChartDataReq, action.request);
            if (res.status === 200) {
                yield put({ type: FETCH_CHART_DATA_SUCCESS, payload: res.data })
            } else console.log(res);
        } catch (err) {
            console.log(err);
        };
    })
}

export function* getGeneralChartData() {
    yield takeEvery(FETCH_GENERAL_CHART_DATA, function* (action) {
        try {
            const res = yield call(getGeneralChartDataReq, action.request);
            console.log({ res });
            yield put({ type: FETCH_GENERAL_CHART_DATA_SUCCESS, payload: res, currency: action.request.currency })
        } catch (err) {
            console.log(err);
        }
    })
}

export function* getRateData() {
    yield takeEvery(FETCH_RATE_DATA, function* (action) {
        try {
            const res = yield call(getRateDataReq, action.payload);
            if (res.status === 200) {
                yield put({ type: FETCH_RATE_DATA_SUCCESS, payload: res })
            } else console.log(res);
        } catch (err) {
            console.log(err);
        }
    })
}

export function* getCurrencyExchangeRate() {
    yield takeEvery(FETCH_CURRENCY_EXCHANGE_RATE, function* (action) {
        try {
            const res = yield call(getCurrencyExchangeRateReq, action.request);
            console.log({ res });
            yield put({ type: FETCH_CURRENCY_EXCHANGE_RATE_SUCCESS, payload: res })
        } catch (err) {
            console.log(err)
        }
    })
}

export function* getCustomChartData() {
    yield takeEvery(FETCH_CUSTOM_CHART_DATA, function* (action) {
        try {
            const res = yield call(getCustomChartDataReq, action.request);
            if (res.status === 200) {
                let customResponse = [];
                res.data.values.map(v => {
                    if (v.x >= action.request.startingTimeStamp && v.x <= action.request.endingTimeStamp) {
                        customResponse.push(v);
                    }
                })
                let tempdata = { ...res.data, values: customResponse }
                yield put({ type: FETCH_CHART_DATA_SUCCESS, payload: tempdata })
            }
        } catch (err) {
            console.log(err);
        }
    })
}

export function* getChartByRange() {
    yield takeEvery(FETCH_CHART_BY_RANGE, function* (action) {
        try {
            const res = yield call(getCustomChartDataReq, action.request);
            if (res.status === 200) {
                let customResponse = [];
                res.data.values.map(v => {
                    if (v.y >= action.request.startingRange && v.y <= action.request.endingRange) {
                        customResponse.push(v);
                        return;
                    }
                })
                let tempdata = { ...res.data, values: customResponse }
                yield put({ type: FETCH_CHART_DATA_SUCCESS, payload: tempdata })
            }
        } catch (err) {
            console.log(err);
        }
    })
}

export function* getChartDataByDateAndRange() {
    yield takeEvery(FETCH_CHART_BY_DATE_AND_RANGE, function* (action) {
        console.log(action.request)
        try {
            const res = yield call(getCustomChartDataReq, action.request);
            if (res.status === 200) {
                let customResponse = [];
                res.data.values.map(v => {
                    if (v.x >= action.request.startingTimeStamp && v.x <= action.request.endingTimeStamp) {
                        if (v.y >= action.request.startingRange && v.y <= action.request.endingRange) {
                            customResponse.push(v);
                            return;
                        }
                    }
                })
                let tempdata = { ...res.data, values: customResponse }
                yield put({ type: FETCH_CHART_DATA_SUCCESS, payload: tempdata })
            }
        } catch (err) {
            console.log(err);
        }
    })
}

function getChartDataReq(request) {
    return axios.get(`https://api.blockchain.info/charts/${request.type}?cors=true&timespan=${request.timespan}&rollingAverage=8hours&format=json`)
}


function getRateDataReq() {
    return axios.get(`https://blockchain.info/ticker`)
}

function getCustomChartDataReq(request) {
    return axios.get(`https://api.blockchain.info/charts/${request.type}?cors=true&timespan=all&rollingAverage=8hours&format=json`)
}

function getCurrencyExchangeRateReq() {
    return axios.get("https://v6.exchangerate-api.com/v6/c73a983b4e6de79fcfaab3a2/latest/USD");
}

async function getGeneralChartDataReq(request) {
    console.log(request)
    var datas = [];
    await axios.get(`https://api.blockchain.info/charts/market-price?cors=true&timespan=${request.timespan}&rollingAverage=8hours&format=json`).then((res) => datas.push(res.data)).catch(err => console.log(err));
    await axios.get(request.timespan !== "7days" ? `https://api.blockchain.info/charts/total-bitcoins?cors=true&timespan=${request.timespan}&rollingAverage=8hours&format=json` : `https://api.blockchain.info/charts/total-bitcoins?cors=true&rollingAverage=8hours&format=json`).then((res) => datas.push(res.data)).catch(err => console.log(err));
    await axios.get(request.timespan !== "7days" ? `https://api.blockchain.info/charts/market-cap?cors=true&timespan=${request.timespan}&rollingAverage=8hours&format=json` : `https://api.blockchain.info/charts/market-cap?cors=true&rollingAverage=8hours&format=json`).then((res) => datas.push(res.data)).catch(err => console.log(err));
    await axios.get(`https://api.blockchain.info/charts/trade-volume?cors=true&timespan=${request.timespan}&rollingAverage=8hours&format=json`).then((res) => datas.push(res.data)).catch(err => console.log(err));
    console.log(datas);
    return datas;
}

export default function* appSaga() {
    yield all(
        [
            fork(getChartData),
            fork(getRateData),
            fork(getCustomChartData),
            fork(getChartByRange),
            fork(getChartDataByDateAndRange),
            fork(getGeneralChartData),
            fork(getCurrencyExchangeRate)
        ]
    );
}
