import React, { PureComponent } from 'react';
import CanvasJSReact from './canvasjs.react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import actions from 'redux/action';
import { Checkbox, FormControlLabel, Grid } from '@material-ui/core';

var CanvasJSChart = CanvasJSReact.CanvasJSChart;
const { fetchGeneralChart } = actions;

class Chart extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            theme: `${localStorage.getItem('theme')}1`,
            startingDate: null,
            endingDate: null,
            chartProps: {},
            marketCap: true,
            marketPrice: true,
            dailyTrade: true,
            totalBitcoins: true,
        }
    }

    componentDidMount() {
        this.props.fetchGeneralChart({ timespan: this.props.chartProps.timespan, currency: this.props.chartProps.currency });
    }
    componentDidUpdate(prevProps) {
        if (prevProps.chartProps?.timespan !== this.props.chartProps?.timespan || prevProps.chartProps?.currency !== this.props.chartProps?.currency) {
            this.props.fetchGeneralChart({ timespan: this.props.chartProps?.timespan, currency: this.props.chartProps.currency })
        }
    }

    render() {
        const { generalChartData } = this.props;
        if (this.props.generalChartData) {
            return (
                <div>
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        spacing={1}
                    >
                        <Grid item xs={12} sm={6}>
                            <CanvasJSChart
                                options={{
                                    backgroundColor: null,
                                    theme: `${this.props.chartProps.theme}1`,
                                    zoomEnabled: true,
                                    height: 400,
                                    title: {
                                        text: "Market Price"
                                    },
                                    axisX: {
                                        valueFormatString: 'DD MMMM YYYY'
                                    },
                                    axisY: {
                                        valueFormatString: "####0",
                                        prefix: this.props.chartProps.currency
                                    },
                                    toolTip: {
                                        contentFormatter: (e) => {
                                            if (isMobile) {
                                                switch (this.props.chartProps.type) {
                                                    case 'market-price':
                                                        return `${e.entries[0].dataPoint.x.toString().slice(4, 15)} : ${e.entries[0].dataPoint.y}k`;
                                                    case 'total-bitcoins':
                                                        return `${e.entries[0].dataPoint.x.toString().slice(4, 15)} : ${e.entries[0].dataPoint.y}M`;
                                                    case 'market-cap':
                                                        return `${e.entries[0].dataPoint.x.toString().slice(4, 15)} : ${e.entries[0].dataPoint.y}G`;
                                                    case 'trade-volume':
                                                        return `${e.entries[0].dataPoint.x.toString().slice(4, 15)} : ${e.entries[0].dataPoint.y}M`;
                                                    default:
                                                        break;
                                                }
                                            }
                                            return `${e.entries[0].dataPoint.x.toString().slice(4, 15)} : ${e.entries[0].dataPoint.y}`;
                                        }
                                    },
                                    data: [
                                        {
                                            type: 'line',
                                            color: 'green',
                                            dataPoints: this.state.marketPrice ? generalChartData[0] : []
                                        }
                                    ]
                                }
                                }
                                onRef={ref => this.chart = ref}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <CanvasJSChart
                                options={{
                                    backgroundColor: null,
                                    theme: `${this.props.chartProps.theme}1`,
                                    zoomEnabled: true,
                                    height: 400,
                                    title: {
                                        text: "Total Bitcoins"
                                    },
                                    axisX: {
                                        valueFormatString: 'DD MMMM YYYY'
                                    },
                                    axisY: {
                                        valueFormatString: "####0",
                                        prefix: '₿'
                                    },
                                    toolTip: {
                                        contentFormatter: (e) => {
                                            if (isMobile) {
                                                switch (this.props.chartProps.type) {
                                                    case 'market-price':
                                                        return `${e.entries[0].dataPoint.x.toString().slice(4, 15)} : ${e.entries[0].dataPoint.y}k`;
                                                    case 'total-bitcoins':
                                                        return `${e.entries[0].dataPoint.x.toString().slice(4, 15)} : ${e.entries[0].dataPoint.y}M`;
                                                    case 'market-cap':
                                                        return `${e.entries[0].dataPoint.x.toString().slice(4, 15)} : ${e.entries[0].dataPoint.y}G`;
                                                    case 'trade-volume':
                                                        return `${e.entries[0].dataPoint.x.toString().slice(4, 15)} : ${e.entries[0].dataPoint.y}M`;
                                                    default:
                                                        break;
                                                }
                                            }
                                            return `${e.entries[0].dataPoint.x.toString().slice(4, 15)} : ${e.entries[0].dataPoint.y}`;
                                        }
                                    },
                                    data: [
                                        {
                                            type: 'line',
                                            color: 'green',
                                            dataPoints: this.state.marketPrice ? generalChartData[1] : []
                                        }
                                    ]
                                }
                                }
                                onRef={ref => this.chart = ref}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <CanvasJSChart
                                options={{
                                    backgroundColor: null,
                                    theme: `${this.props.chartProps.theme}1`,
                                    zoomEnabled: true,
                                    height: 400,
                                    title: {
                                        text: "Market Capitalization"
                                    },
                                    axisX: {
                                        valueFormatString: 'DD MMMM YYYY'
                                    },
                                    axisY: {
                                        valueFormatString: "####0",
                                        prefix: this.props.chartProps.currency
                                    },
                                    toolTip: {
                                        contentFormatter: (e) => {
                                            if (isMobile) {
                                                switch (this.props.chartProps.type) {
                                                    case 'market-price':
                                                        return `${e.entries[0].dataPoint.x.toString().slice(4, 15)} : ${e.entries[0].dataPoint.y}k`;
                                                    case 'total-bitcoins':
                                                        return `${e.entries[0].dataPoint.x.toString().slice(4, 15)} : ${e.entries[0].dataPoint.y}M`;
                                                    case 'market-cap':
                                                        return `${e.entries[0].dataPoint.x.toString().slice(4, 15)} : ${e.entries[0].dataPoint.y}G`;
                                                    case 'trade-volume':
                                                        return `${e.entries[0].dataPoint.x.toString().slice(4, 15)} : ${e.entries[0].dataPoint.y}M`;
                                                    default:
                                                        break;
                                                }
                                            }
                                            return `${e.entries[0].dataPoint.x.toString().slice(4, 15)} : ${e.entries[0].dataPoint.y}`;
                                        }
                                    },
                                    data: [
                                        {
                                            type: 'line',
                                            color: 'green',
                                            dataPoints: this.state.marketPrice ? generalChartData[2] : []
                                        }
                                    ]
                                }
                                }
                                onRef={ref => this.chart = ref}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <CanvasJSChart
                                options={{
                                    backgroundColor: null,
                                    theme: `${this.props.chartProps.theme}1`,
                                    zoomEnabled: true,
                                    height: 400,
                                    title: {
                                        text: "Daily Trade Volume"
                                    },
                                    axisX: {
                                        valueFormatString: 'DD MMMM YYYY'
                                    },
                                    axisY: {
                                        valueFormatString: "####0",
                                        prefix: this.props.chartProps.currency
                                    },
                                    toolTip: {
                                        contentFormatter: (e) => {
                                            if (isMobile) {
                                                switch (this.props.chartProps.type) {
                                                    case 'market-price':
                                                        return `${e.entries[0].dataPoint.x.toString().slice(4, 15)} : ${e.entries[0].dataPoint.y}k`;
                                                    case 'total-bitcoins':
                                                        return `${e.entries[0].dataPoint.x.toString().slice(4, 15)} : ${e.entries[0].dataPoint.y}M`;
                                                    case 'market-cap':
                                                        return `${e.entries[0].dataPoint.x.toString().slice(4, 15)} : ${e.entries[0].dataPoint.y}G`;
                                                    case 'trade-volume':
                                                        return `${e.entries[0].dataPoint.x.toString().slice(4, 15)} : ${e.entries[0].dataPoint.y}M`;
                                                    default:
                                                        break;
                                                }
                                            }
                                            return `${e.entries[0].dataPoint.x.toString().slice(4, 15)} : ${e.entries[0].dataPoint.y}`;
                                        }
                                    },
                                    data: [
                                        {
                                            type: 'line',
                                            color: 'green',
                                            dataPoints: this.state.dailyTrade ? generalChartData[3] : []
                                        },
                                    ]
                                }
                                }
                                onRef={ref => this.chart = ref}
                            />
                        </Grid>
                    </Grid>
                </div>
            )
        } else {
            return <div>Loading</div>
        }
    }
}

const mapStateToProps = (state) => {
    console.log(state.DataReducer);
    return {
        generalChartData: state.DataReducer.generalChartData
    }
}

export default connect(mapStateToProps, { fetchGeneralChart })(Chart);